import React from 'react';
import Modal from 'react-modal';
import './ModalContent.css';

const ModalContent = props => {
  return (
    <div>
      <Modal
        isOpen={props.openModal}
        className=" modal-up modal-dialog fadeInDown"
        contentLabel={props.label}>
        <div className="modal-content primary text-center">
          <div className="modal-header primary title-modal">{props.title}</div>
          <div className="modal-body">{props.content}</div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalContent;
