import React from 'react';
import {useEffect, useState} from 'react';
import './RequestFilters.css';
import SelectCustomerDocument from '../common/selects/SelectCustomerDocument';
import SelectRequestState from "../common/selects/SelectRequestState";
import apiHelper from '../../utils/apiHelpers';
import moment from 'moment';

const RequestFilters = props => {
    const [state, setState] = useState({
         customer_document: null,
         request_state: null,
    });

    useEffect(() => {
        props.loadData(getFiltersObject());
    }, [state]);

    const getFiltersObject =  () => {

            let filtersObj = null;
            if (props.user.role === 'ADMIN') {
                filtersObj = {
                    where: {
                    }};
                if (state.customer_document) {
                    filtersObj.where.customer_document = state.customer_document;
                }
                if (state.request_state) {
                    switch(state.request_state) {
                        case 'PA':
                            filtersObj.where.delivered = false;
                            filtersObj.where.pickup_type = "DEPOT";
                            break;
                        case 'A':
                            filtersObj.where.delivered = true;
                            filtersObj.where.pickup_type = "DEPOT";
                            break;
                        case 'NE':
                            filtersObj.where.delivered = false;
                            filtersObj.where.pickup_type = "YPF";
                            break;
                        case 'E':
                            filtersObj.where.delivered = true;
                            filtersObj.where.pickup_type = "YPF";
                            break;
                    }
                }

            }
            if (props.user.role === 'YPF' || props.user.role === 'DEPOT') {

                let today = (moment()).format('YYYY-MM-DD 00:00.000');

                let filtersObj = {
                    where: [
                        {
                            pickup_place: props.user.pickup_place,
                            delivered: false,
                        },
                        {
                            pickup_place: props.user.pickup_place,
                            delivered_date: today,
                            delivered: true,
                        },
                    ],
                };

                if (state.customer_document) {
                    filtersObj.where[0].customer_document = state.customer_document;
                    filtersObj.where[1].customer_document = state.customer_document;
                }
            }
            return filtersObj;
    };

    const onFiltersChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let newState = JSON.parse(JSON.stringify(state));
        newState[name] = value;
        setState(newState);
    };

    const onFiltersRefresh = () => {
         setState({
             customer_document: null,
             request_state: null,
         });
    };

    let stateFilter;
    if (props.user.role === 'ADMIN') {
        stateFilter = <div className="col-lg-4 col-sm-4">
            <SelectRequestState
        value={state.request_state}
        name="request_state"
        onChange={onFiltersChange.bind(this)}
        />
        </div>
    }

        return (
        <div className="row transactions-filters">
            <div className="col-lg-4 col-sm-4">
                <SelectCustomerDocument
                    value={state.customer_document}
                    name="customer_document"
                    onChange={onFiltersChange.bind(this)}
                />
            </div>
            {stateFilter}
            <div className={props.user.role === "ADMIN" ? "col-lg-4 col-sm-4" : "col-lg-8 col-sm-8"}>
                <div className="text-right">
                    <button
                        type="button"
                        className="btn butt btn-warning "
                        onClick={onFiltersRefresh.bind(this)}
                        style={{marginLeft: '2px'}}>
                        Reiniciar filtros <i className="fas fa-sync"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RequestFilters;
