import React from 'react';
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './SideMenu.css';
import apiHelper from "../../utils/apiHelpers";

const NewAside = () => {
    const [user, setUser] = useState({first_name: '', last_name: ''});
    const [collapsed, setCollapsed] = useState(true);
    const [background, setBackground] = useState(false);

    const onClickExpand = () => {
        if (!collapsed) {
            setCollapsed(true);
            setBackground(false);
        } else {
            setCollapsed(false);
            setBackground(true);
        }
    };

    const notBackground = () => {
        setBackground(false);
        setCollapsed(true);
    };

    return (
        <>
        {!collapsed ? (
            <div
                className="backAside fadeInA"
                onClick={notBackground}
                background={background}></div>
        ) : null}
        <nav className={`sidebar ${collapsed ? 'active' : ''}`}>
            <div className=" ml-3 sidebar-header">
                <img
                    src={process.env.PUBLIC_URL + '/img/logoSwift.png'}
                    width="170px"
                    alt="swift-ypf"
                />
                <span className="pl-2 ">
            {user.first_name} {user.last_name}
          </span>
            </div>

            <ul className="list-unstyled components">
                <hr />

                <li>
                    <Link to={`${process.env.PUBLIC_URL}/request`}>
                        <i className="fas fa-box  mr-2 "></i> Listado de pedidos
                    </Link>

                </li>
                <hr />
                <li>
                    <Link to="/login" onClick={apiHelper.logout}>
                        <i className="fas fa-sign-out-alt  mr-2 "></i> Cerrar sesión
                    </Link>
                </li>
            </ul>
        </nav>
        <button
            type="button"
            collapsed={collapsed}
            className={`btn asideButton   ${collapsed ? 'activeButton' : ''}`}
            onClick={onClickExpand}>
            <i className={`${collapsed ? 'fas fa-align-center' : ' fas fa-arrow-circle-right '}`}></i>
        </button>
        </>
    );
};

export default NewAside;
