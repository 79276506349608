const axios = require('axios');
const config = require('../config/config');
const qs = require('qs');

/* APIs ENDPOINTS */
const SESSION_STORAGE_SESSION = 'session';
const SERVER_URL = config.backend.apiUrl;
const LOGIN_URL = SERVER_URL + '/auth/login';
const CHANGE_PASSWORD_URL = SERVER_URL + '/auth/change-password';
const RESET_URL = SERVER_URL + '/auth/reset';
const GENERATE_PASSWORD_URL = SERVER_URL + '/auth/generate-password';
const USERS_URL = SERVER_URL + '/user';
const USER_ROLES_URL = SERVER_URL + '/userRole';
const REQUESTS_URL = SERVER_URL + '/request';
const REQUESTS_INDEX_URL = SERVER_URL + '/request/{id}';
const CURRENT_USER_URL = SERVER_URL + '/user/me';
const REQUESTS_IMPORT_URL = SERVER_URL + '/request/import';
const REQUESTS_SEND_EMAIL_DELIVERED_URL = SERVER_URL + '/request/sendEmailDelivered/{id}';

function getBackendUrl() {
    return SERVER_URL;
}

// Auth APIs

function login(payload) {
    return axios.post(LOGIN_URL, payload).then(function (response) {
        const token = response.data.token;
        sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify({token: response.data.token}),);
        getCurrentUser().then(function (userResponse) {
            let userData = userResponse.data;
            userData.token = token;
            sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify(userData));
            return userData;
        });
    });
}

function resetPassword(payload) {
    return axios
        .post(RESET_URL, {
            username: payload.email,
        })
        .then(function (response) {
            return response;
        });
}

function changePassword(payload) {
    return axios
        .post(
            CHANGE_PASSWORD_URL,
            {
                username: payload.username,
                password: payload.password,
                newPassword: payload.newPassword,
            },
            buildHeaders(),
        )
        .then(function (response) {
            return response;
        });
}

function generatePassword(payload) {
    return axios
        .post(GENERATE_PASSWORD_URL, payload, buildHeaders())
        .then(function (response) {
            return response;
        });
}

function logout() {
    sessionStorage.clear(SESSION_STORAGE_SESSION);
}

function isAuthenticated() {
    return (
        sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token != null &&
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token != ''
    );
}
function isAdmin() {
    return (
        sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role === 'ADMIN'
    );
}

function isDepot() {
    return (
        sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role === 'DEPOT'
    );
}

function isYPF() {
    return (
        sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role === 'YPF'
    );
}

function buildHeaders() {
    if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
        return {
            headers: {
                Authorization: 'Bearer ' +
                JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token,
            },
        };
    } else {
        return null;
    }
}

// User APIs

function getUsers(parameters = []) {
    return axios.get(
        `${USERS_URL}?${qs.stringify(parameters, {skipNulls: true})}`,
        buildHeaders(),
    );
}

function getCurrentUser() {
    return axios.get(CURRENT_USER_URL, buildHeaders());
}

function getUsersCount(parameters = []) {
    return axios.get(
        `${USERS_URL + '/count'}?${qs.stringify(parameters, {skipNulls: true})}`,
        buildHeaders(),
    );
}

function getUser(id) {
    return axios.get(USERS_URL + '/' + id, buildHeaders());
}

function postUser(body) {
    return axios.post(USERS_URL + '/', body, buildHeaders());
}

function patchUser(id, body) {
    return axios.patch(USERS_URL + '/' + id, body, buildHeaders());
}

function deleteUser(id) {
    return axios.delete(USERS_URL + '/' + id, buildHeaders());
}

function getUserRoles(parameters = []) {
    return axios.get(
        `${USER_ROLES_URL}?${qs.stringify(parameters, {skipNulls: true})}`,
        buildHeaders(),
    );
}

function getRequests(parameters = []) {
    return axios.get(
        `${REQUESTS_URL}?${qs.stringify(parameters, {
            skipNulls: true,
        })}`,
        buildHeaders(),
    );
}

function getRequest(requestId) {
    return axios.get(
        REQUESTS_INDEX_URL.replace('{id}', requestId),
        buildHeaders(),
    );
}

function deleteRequest(RequestId) {
    return axios.delete(
        REQUESTS_INDEX_URL.replace('{id}', RequestId),
        buildHeaders(),
    );
}

function postRequest(payload) {
    return axios.post(REQUESTS_URL, payload, buildHeaders());
}

function patchRequest(requestId, payload) {
    let headers = buildHeaders();
    headers.responseType = 'blob';
    return axios.patch(
        REQUESTS_INDEX_URL.replace('{id}', requestId),
        payload,
        headers,
    );
}

function sendEmailDelivered(requestId) {
    return axios.get(REQUESTS_SEND_EMAIL_DELIVERED_URL.replace('{id}', requestId), buildHeaders());
}

function importRequests(payload) {
    const data = new FormData();
    data.append('file', payload);
    return axios.post(REQUESTS_IMPORT_URL, data, buildHeaders());
}

function getRequestsCount(parameters) {
    return axios.get(
        `${REQUESTS_URL + '/count'}?${qs.stringify(parameters, {
            skipNulls: true,
        })}`,
        buildHeaders(),
    );
}
export default {
    SERVER_URL,
    getBackendUrl,
    logout,
    login,
    resetPassword,
    changePassword,
    generatePassword,
    isAuthenticated,
    isAdmin,
    isDepot,
    isYPF,
    getUsers,
    getUsersCount,
    getUser,
    postUser,
    patchUser,
    deleteUser,
    getCurrentUser,
    getUserRoles,
    getRequestsCount,
    postRequest,
    patchRequest,
    deleteRequest,
    getRequests,
    getRequest,
    importRequests,
    sendEmailDelivered,
};
