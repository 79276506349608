import React, {useState} from 'react';
import Login from '../login/Login';
import {Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import './App.css';
import PrivateRoute from '../../utils/PrivateRoute';
import UserDetail from '../user/UserDetail';
import User from '../user/User';
import Request from '../request/Request';
import RequestDetail from '../request/RequestDetail';
import NewAside from '../common/SideMenu';
import Footer from '../common/Footer';
import RecoverPassword from '../login/RecoverPassword';
import {VTasksWidget} from 'vtasks-widget';

const history = createBrowserHistory();

function App() {
    return (
        <>
        <VTasksWidget
            project="XXXXXXXXXXXXXXXXXXXXXXXX"
            environmentLabel="XXXXXX DEV"
            environmentUrl="dev.XXXXX.venturing.com.ar"
            apikey="XXXXXXXXXXXXXXXXX"
            vTasksUrl="http://dev.vtasks.venturing.com.ar"
            vTasksAPIUrl="https://dev.XXXXXX.venturing.com.ar/XXXXXX/api"
        />
        <Router history={history}>
            <Switch>
                <Route name="login" path={`/login`} component={Login}/>
                <Route
                    name="recover_password"
                    path={`/recover_password`}
                    component={RecoverPassword}
                />
                <div>
                    <NewAside />
                    <PrivateRoute name="userDetail" exact path={`/user/:id`} component={UserDetail}/>
                    <PrivateRoute name="user" exact path={`/user`} component={User}/>
                    <PrivateRoute name="request" exact path={`/request/:id`} component={RequestDetail}/>
                    <PrivateRoute name="request" exact path={`/request`} component={Request}/>
                    <PrivateRoute name="default" exact path={`/`} component={Request}/>
                    <Footer />
                </div>
            </Switch>
        </Router>
        </>
    );
}

export default App;
