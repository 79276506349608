import React from 'react';
import {useEffect, useState} from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import formatter from '../../utils/formatter';
import Tooltip from '../common/Tooltip';
import SelectUserRole from '../common/selects/SelectUserRole';
import StickyHeader from './../common/StickyHeader';

const UserDetail = props => {
    const [user, setUser] = useState({
        username: '',
        email: '',
        password: '',
        role: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isNew] = useState(props.match.params.id === 'new');

    useEffect(() => {
        if (!isNew) {
            apiHelper
                .getUser(props.match.params.id)
                .then(response => {
                    setUser(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, []);

    const handleChange = event => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        value =
            target.type === 'react-datetime'
                ? formatter.formatDateForAPI(target.value)
                : target.value;
        const name = target.name;
        const newUser = Object.assign({}, user);
        newUser[name] = value;
        setUser(newUser);
    };

    const deleteUser = async () => {
        await apiHelper.deleteUser(user.id);
        props.history.push('/user');
    };

    const submitUser = async () => {
        try {
            setLoading(true);
            //If request already exists, update it. If not, create it
            if (isNew) {
                await apiHelper.postUser(user);
            } else {
                await apiHelper.patchUser(user.id, user);
            }
            setLoading(false);
            props.history.push('/user');
        } catch (error) {
            setLoading(false);
            const statusCode = error.response.status;
            let errorMessage;
            if (statusCode === 422) {
                errorMessage = 'Datos invalidos o incompletos';
            } else {
                errorMessage = error.response.data.error
                    ? error.response.data.error.message
                    : error.response.data;
            }
            setErrorMessage(errorMessage);
        }
    };

    let alert;
    let title;
    let deleteButton;

    if (errorMessage) {
        alert = <div className="alert alert-warning">{errorMessage}</div>;
    }

    if (isNew) {
        title = 'Crear Usuario';
    } else {
        title = user.name;
        deleteButton = (
            <button
                className="btn btn-danger pull-right"
                type="button"
                onClick={e => deleteUser()}>
                <i className="fa fa-trash-alt"/> Eliminar
            </button>
        );
    }

    return (
        <div className="d-flex flex-column h-100 general">
            <StickyHeader titulo={title} icon={'fas fa-user icon-vtasks'}/>
            <div className="container">
                <div className="row mb-1 mt-3 justify-content-end">
                    <button className="btn butt mr-3" onClick={e => submitUser()}>
                        Guardar <i className="fas fa-save"/>
                    </button>
                    {deleteButton}
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="username">Usuario</label>
                            <span
                                data-tip
                                data-for={'Tooltip-username'}
                                className="ml-1 fa fa-info-circle"></span>
                            <Tooltip
                                id={'Tooltip-username'}
                                tooltipText={
                                    'Nombre de usuario. Junto con la contraseña, se utilizará para iniciar sesión.'
                                }
                            />
                            <input
                                type="text"
                                name="username"
                                className="form-control"
                                placeholder="No definido"
                                value={user.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="password">Contraseña</label>
                            <span
                                data-tip
                                data-for={'Tooltip-password'}
                                className="ml-1 fa fa-info-circle"></span>
                            <Tooltip
                                id={'Tooltip-password'}
                                tooltipText={
                                    'Contraseña del usuario. Junto con el nombre de usuario, se utilizará para iniciar sesión.'
                                }
                            />
                            <input
                                type="text"
                                name="password"
                                className="form-control"
                                placeholder="No definido"
                                value={user.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <span
                                data-tip
                                data-for={'Tooltip-email'}
                                className="ml-1 fa fa-info-circle"></span>
                            <Tooltip
                                id={'Tooltip-email'}
                                tooltipText={
                                    'Email del usuario. Se utilizará para recibir las notificaciones del sistema.'
                                }
                            />
                            <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="No definido"
                                value={user.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="userRole">Rol</label>
                            <span
                                data-tip
                                data-for={'Tooltip-role'}
                                className="ml-1 fa fa-info-circle"></span>
                            <Tooltip
                                id={'Tooltip-role'}
                                tooltipText={'Rol del usuario.'}
                            />
                            <SelectUserRole
                                value={user.role}
                                name={'role'}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                {alert}
            </div>
        </div>
    );
};

export default UserDetail;
