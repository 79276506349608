import React from 'react';
import {useEffect, useState} from 'react';
import apiHelper from '../../utils/apiHelpers';
import {Link} from 'react-router-dom';
import Footer from '../common/Footer';
import Pagination from '../common/Pagination';
import './Request.css';
import RequestFilters from './RequestFilters';
import StickyHeader from '../common/StickyHeader';
import ModalContent from '../common/ModalContent';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '../common/Tooltip';
import formatter from '../../utils/formatter';
import moment from 'moment';

const Request = () => {
        const [requests, setRequests] = useState([]);
        const [newRequests, setNewRequests] = useState([]);
        const [filters, setFilters] = useState({});
        const [count, setCount] = useState(0);
        const [pageSize] = useState(25);
        const [currentPage, setCurrentPage] = useState(1);
        const [user, setUser] = useState({role: ''});
        const [openModal, setOpenModal] = useState(false);
        const [openModalAlert, setOpenModalAlert] = useState(false);
        const [loading, setLoading] = useState(false);
        const [document, setDocument] = useState(null);
        const [importErrors, setImportErrors] = useState([]);
        const [importSuccess, setImportSuccess] = useState(false);

        useEffect(() => {
            getRequests();
            apiHelper.getCurrentUser().then(user => setUser(user.data));
        }, []);

        useEffect(() => {
            getRequests();
        }, [filters]);

        useEffect(() => {
            getRequests();
        }, [currentPage]);

        const handleChangeFilters = filters => {
            setFilters(filters);
        };

        const handleChangePage = pageOfItems => {
            setCurrentPage(pageOfItems);
        };

        const getNumberOfPages = () => {
            return Math.ceil(count / pageSize);
        };

        const onChangeFile = (event) => {
            event.preventDefault();
            setNewRequests(event.target.files[0]);
            setDocument(true);
        };

        const getRequests = async () => {
            try {
                let requests;
                let requestsCount;
                let filtersWithPaging = {
                    ...filters,
                    limit: pageSize,
                    skip: (currentPage - 1) * pageSize,
                };
                requestsCount = await apiHelper.getRequestsCount(filters);
                requests = await apiHelper.getRequests(filtersWithPaging);
                setRequests(requests.data);
                setCount(requestsCount.data.count);
            }
            catch (e) {
                console.log('Error loading request');
            }
        };

        const importRequests = async () => {

            setLoading(true);
            const response = await apiHelper.importRequests(newRequests);
            if (response.data.success) {
                setOpenModal(false);
                setImportSuccess(true);
            } else {
                setOpenModal(false);
                const errors = response.data.errors;

                if (errors.length > 0) {
                    setImportErrors(errors);
                    setOpenModalAlert(true);

                }
            }
            await getRequests();
        };

        const deliverRequests = async (request) => {

            request.delivered ? request.delivered = false : request.delivered = true;
            let response = await apiHelper.patchRequest(request.id, request);
            if (response.status === 200 && request.delivered === true && !apiHelper.isDepot()) {
                await apiHelper.sendEmailDelivered(request.id);
            }
            getRequests();

        };
        const contentOfModal = (
            <div>
                {openModal && (
                    <div className=" text-center">
                        <div>
                            <p className="text-modal-content">Importar pedidos</p>
                        </div>
                        <div>
                            <div className="align-content-center">
                                <div>
                                    <a href="/docs/modelo_para_pedidos.csv"
                                       className=" creation-info download-document alert-dismissible"
                                       target="_blank">
                                        <i className=" ml-5 fas fa-file-alt fa-sm fa-fw mr-2"></i>{' '}
                                        Archivo modelo
                                    </a>
                                </div>

                                <form method="post" action="" encType="multipart/form-data">
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        className=" mt-3 mb-3"
                                        onChange={e => onChangeFile(e)}
                                    />
                                    <button
                                        type="button"
                                        className="btn butt clickable btn-info"
                                        id="upload"
                                        onClick={(e) => importRequests()}
                                        disabled={!document}>
                                        Importar pedidos{' '}
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"
                                                  role="status"
                                                  aria-hidden="true"></span>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );

        const alertError = (
            <div>{openModalAlert &&
            <Alert
                severity="error"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="medium"
                        onClick={() => setOpenModalAlert(false)}>
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                }>
                <AlertTitle>Error de importación</AlertTitle>

                {importErrors && importErrors.map(importErrors => {
                    return (
                        <tr key={importErrors.line}>
                            <td>
                                <p>
                                    {'Error en la linea '}
                                    {importErrors.line}:<b> {importErrors.message}</b>
                                </p>
                            </td>
                        </tr>
                    );
                })}
            </Alert> }</div>

        );

        const alertImportSuccess = (
            <div>{importSuccess &&
            <Alert
                severity="success"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={(e) => setImportSuccess(false)}>
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                }>
                <AlertTitle>Importación</AlertTitle>
                <strong>Todos los pedidos se importaron correctamente</strong>
            </Alert> }</div>

        );


        const modalDataOperation = (
            <div className="col-lg-12">{openModal &&
            <p className="ml-3">Importar pedidos
                <i className=" clickable pull-right fas fa-times fa-sm fa-fw "
                   onClick={e => setOpenModal(false)}></i>{' '}
            </p> }</div>
        );

        return (
            <div className="d-flex flex-column h-100 general">
                <StickyHeader titulo={'Pedidos'} icon={'fas fa-request icon-vtasks'}/>
                <ModalContent title={[modalDataOperation]} openModal={openModal} content={[contentOfModal]}></ModalContent>
                <div className="container">
                    <div className="container mt-3">
                        {apiHelper.isAdmin() && <>
                        <button
                            type="button"
                            className="btn butt ml-3 btn-success pull-right "
                            onClick={() => setOpenModal(true)}>
                            Importar <i className="fas fa-file-csv"/>
                        </button>
                        <Link to={`${process.env.PUBLIC_URL}/request/new`}>
                            <button type="button" className="btn butt pull-right">
                                Agregar <i className="fas fa-plus-square"/>
                            </button>
                        </Link>
                        </>
                        }


                        <RequestFilters loadData={handleChangeFilters} user={user}/>
                        {alertError}
                        {alertImportSuccess}
                        <div className="table-responsive mt-3">
                            <table className="table  table-hover">
                                <thead>
                                <tr>
                                    <th className="text-center" scope="col">ID de pedido</th>
                                    <th className="text-center" scope="col">Estado</th>
                                    <th className="text-center" scope="col">DNI</th>
                                    <th className="text-center" scope="col">Punto de retiro</th>
                                    <th className="text-center" scope="col">{apiHelper.isDepot()? 'Fecha de armado': 'Fecha de retiro'}</th>
                                    <th className="text-center" scope="col">Descripción</th>
                                    <th className="text-center" scope="col">Acciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                {requests.map(request => {
                                    return (
                                        <tr key={request.id}>
                                            <td className="text-center">{request.request_number}</td>
                                            <td className="text-center">{request.delivered ? 'Entregado' : 'No entregado'}</td>
                                            <td className="text-center">{request.customer_document}</td>
                                            <td className="text-center">{request.pickup_place}</td>
                                            <td className="text-center">{request.delivered_date ? formatter.formatDate(request.delivered_date) : '--'}</td>
                                            <td className="text-center">{request.description.length > 15 ? <><i
                                                data-tip
                                                data-for={'Tooltip-products'}
                                                className="ml-4 info fa-lg fa fa-info-circle"></i>
                                            < Tooltip
                                                id={'Tooltip-products'}
                                                tooltipText={request.description}
                                            /></> : request.description}
                                            </td>
                                            <td>
                                                <div className="text-center">

                                                    {!request.delivered ? <div className="btn-group">
                                                        <button
                                                            type="button"
                                                            className="btn buttEdit btn-light btn-circle">
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/request/${request.id}`}>
                                                                <span className="fa fa-edit text-white"/>
                                                            </Link>
                                                        </button>
                                                        <button
                                                            data-tip
                                                            data-for={'Tooltip-delivered'}
                                                            type="button"
                                                            className="btn buttEdit btn-circle"
                                                            onClick={() => deliverRequests(request)}>
                                                            <i className="fas fa-check text-white"></i>
                                                            < Tooltip
                                                                id={'Tooltip-delivered'}
                                                                tooltipText= {apiHelper.isDepot()? 'Marcar como ARMADO': 'Marcar como ENTREGADO'}

                                                            />
                                                        </button>
                                                    </div> : <div className="btn-group">
                                                        <button
                                                            type="button"
                                                            className="btn buttEdit btn-light btn-circle">
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/request/${request.id}`}>
                                                            <span
                                                                className="fa fa-edit text-white"/>
                                                            </Link>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            data-tip
                                                            data-for={'Tooltip-not-delivered'}
                                                            className="btn buttEdit btn-circle"
                                                            onClick={() => deliverRequests(request)}>
                                                            <i className="fas fa-times text-white"></i>
                                                            < Tooltip
                                                                id={'Tooltip-not-delivered'}
                                                                tooltipText= {apiHelper.isDepot()? 'Marcar como NO ARMADO': 'Marcar como NO ENTREGADO'}
                                                            />
                                                        </button>
                                                    </div>

                                                    }
                                                </div>

                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="text-center">
                        <Pagination
                            onChangePage={handleChangePage}
                            currentPage={currentPage}
                            totalPages={getNumberOfPages()}
                        />
                    </div>
                </div>
            </div>
        );
    }
;

export default Request;
