import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import {useEffect, useState} from 'react';

const SelectUserName = props => {
    const [options, setOptions] = useState([]);

    const [input, setInput] = useState('');

    const [value, setValue] = useState(null);

    useEffect(() => {
        let {value} = props;
        let userValue;
        if (value) {
            userValue = options.find(option => option.id === value.id);
            if (!userValue && value.id) loadDefaultOptions(value);
        }

    }, [props.value]);

    const handleChange = selectedValue => {
        setValue(selectedValue);
        let event = {target: {}};
        event.target.name = props.name ? props.name : 'select-options-nameless';
        event.target.value = selectedValue ? selectedValue.value : null;
        event.target.type = 'react-select';
        props.onChange(event);
    };

    const loadOptions = (input, callback) => {
        if (!input.length) return;

        let filters = {
            where: {
                customer_document: input,
            },
        };

        apiHelper
            .getRequests(filters)
            .then(response => {
                let entitiesForSelect = response.data.map(entity => {
                    return {
                        value: entity,
                        label: entity.customer_document,
                    };
                });

                setOptions(entitiesForSelect);
                callback(entitiesForSelect);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const loadDefaultOptions = value => {
        if (!value.id) return;

        let filters = {
            where: {
                id: value.id,
            },
        };

        apiHelper
            .getUsers(filters)
            .then(response => {
                let entitiesForSelect = response.data
                    .sort((a, b) => {
                        if (a.id > b.id) return 1;
                        if (a.id < b.id) return -1;
                        return 0;
                    })
                    .map(entity => {
                        return {
                            value: entity,
                            label: entity.customer_document,
                        };
                    });

                setOptions(entitiesForSelect);
                setValue({value: value, label: value.customer_document});
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <AsyncSelect
            onChange={handleChange}
            loadOptions={loadOptions}
            value={value}
            name={props.name}
            required={props.required}
            placeholder="DNI"
            noOptionsMessage={() =>
                'No se encontraron pedido con para el DNI ingresado'
            }
        />
    );
};

export default SelectUserName;
