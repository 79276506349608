import React from 'react';
import {useEffect, useState} from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import formatter from '../../utils/formatter';
import Tooltip from '../common/Tooltip';
import StickyHeader from './../common/StickyHeader';
import SelectDeliveredRequest from '../common/selects/SelectDeliveredRequest';
import SelectDates from "../common/selects/SelectDates";

const RequestDetail = props => {
    const [request, setRequest] = useState({
        request_number: '',
        delivered: '',
        pickup_place: '',
        products: '',
        customer_email: '',
        customer_document: '',
        customer_name: '',
        purchase_date: '',
        description: '',
        value: '',
        postal_code: '',
        phone: '',
        city: '',
        location: '',
        delivered_date: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isNew] = useState(props.match.params.id === 'new');

    useEffect(() => {
        if (!isNew) {
            apiHelper
                .getRequest(props.match.params.id)
                .then(response => {
                    setRequest(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, []);

    const handleChange = event => {

        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        value =
            target.type === 'react-datetime'
                ? formatter.formatDateForAPI(target.value)
                : target.value;
        const name = target.name;
        const newRequest = Object.assign({}, request);
        newRequest[name] = value;
        setRequest(newRequest);
    };

    const deleteRequest = async () => {
        await apiHelper.deleteRequest(request.id);
        props.history.push('/request');
    };

    const submitRequest = async () => {
        try {
            let filterEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

            if (request.customer_email && !filterEmail.test(request.customer_email)) {
                setErrorMessage('El mail ingresado es incorrecto: ejemplo@mail.com');
                return;
            }

            if (!request.customer_document || request.customer_document === undefined) {
                setErrorMessage('Complete el número de documento del cliente');
                return;
            }

            if (!request.pickup_place || request.pickup_place === undefined) {
                setErrorMessage('Complete el lugar de retiro');
                return;
            }
            setLoading(true);
            //If request already exists, update it. If not, create it
            if (isNew) {
                await apiHelper.postRequest(request);
            } else {
                await apiHelper.patchRequest(request.id, request);
            }
            setLoading(false);
            props.history.push('/request');
        } catch (error) {
            setLoading(false);
            const statusCode = error.response.status;
            let errorMessage;
            if (statusCode === 422) {
                errorMessage = 'Datos invalidos o incompletos';
            } else {
                errorMessage = error.response.data.error
                    ? error.response.data.error.message
                    : error.response.data;
            }
            setErrorMessage(errorMessage);
        }

    };

    let alert;
    let title;
    let deleteButton;

    if (errorMessage) {
        alert = <div className="alert text-center alert-warning">{errorMessage}</div>;
    }

    if (isNew) {
        title = 'Crear Pedido';
    } else {
        title = request.name;
        deleteButton = (
            <button
                className="btn butt btn-danger pull-right"
                type="button"
                onClick={e => deleteRequest()}>
                <i className="fa fa-trash-alt"/> Eliminar
            </button>
        );
    }

    return (
        <div className="d-flex flex-column h-100 general">
            <StickyHeader titulo={title} icon={'fas fa-request icon-vtasks'}/>
            <div className="container">
                <div className="row mb-1 mt-3 mr-1 justify-content-end">
                    <button className="btn butt mr-3" onClick={e => submitRequest()}>
                        Guardar <i className="fas fa-save"/>
                    </button>
                    {deleteButton}
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="requestname">ID de Pedido</label>
                            <input
                                type="text"
                                name="request_number"
                                className="form-control"
                                placeholder="No definido"
                                value={request.request_number}
                                onChange={handleChange}
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="customer_name">Nombre del cliente</label>
                            <input
                                type="text"
                                name="customer_name"
                                className="form-control"
                                placeholder="No definido"
                                value={request.customer_name}
                                onChange={handleChange}
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="customer_document">DNI del Cliente</label>
                            <input
                                type="text"
                                name="customer_document"
                                className="form-control"
                                placeholder="No definido"
                                value={request.customer_document}
                                onChange={handleChange}
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="customer_email">Email de cliente</label>
                            <input
                                id="email"
                                type="email"
                                className="form-control"
                                value={request.customer_email}
                                name="customer_email"
                                placeholder="No definido"
                                onChange={handleChange}
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="phone">Teléfono</label>
                            <input
                                type="text"
                                value={request.phone}
                                name="phone"
                                onChange={handleChange}
                                placeholder="No definido"
                                className="form-control"
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="pickup_place">Lugar de retiro</label>
                            <input
                                type="text"
                                value={request.pickup_place}
                                name="pickup_place"
                                onChange={handleChange}
                                placeholder="No definido"
                                className="form-control"
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="location">Localidad</label>
                            <input
                                type="text"
                                value={request.location}
                                name="location"
                                onChange={handleChange}
                                placeholder="No definido"
                                className="form-control"
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="city">Provincia</label>
                            <input
                                type="text"
                                value={request.city}
                                name="city"
                                onChange={handleChange}
                                placeholder="No definido"
                                className="form-control"
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="postal_code">Código postal</label>
                            <input
                                type="text"
                                value={request.postal_code}
                                name="postal_code"
                                onChange={handleChange}
                                placeholder="No definido"
                                className="form-control"
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="products">Fecha de compra</label>
                            <SelectDates
                                value={request.purchase_date}
                                name="purchase_date"
                                placeholder="No definido"
                                onChange={handleChange}
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="products">Fecha de retiro</label>
                            <SelectDates
                                value={request.delivered_date}
                                name="purchase_date"
                                placeholder="No definido"
                                onChange={handleChange}
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="value">Valor total</label>
                            <input
                                type="text"
                                value={request.value}
                                name="value"
                                onChange={handleChange}
                                placeholder="No definido"
                                className="form-control"
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="delivered">Estado</label>
                            <SelectDeliveredRequest
                                value={request.delivered}
                                name="delivered"
                                placeholder="No definido"
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-9">
                        <div className="form-group">
                            <label htmlFor="description"> Descripción de la compra</label>
                            <i
                                data-tip
                                data-for={'Tooltip-products'}
                                className="ml-1 info fa-lg fa fa-info-circle"></i>
                            <Tooltip
                                id={'Tooltip-products'}
                                tooltipText={
                                    'Escribir en detalle los productos asociados al pedido'
                                }
                            />
                            <textarea
                                value={request.description}
                                name="description"
                                placeholder="No definido"
                                onChange={handleChange}
                                className="form-control"
                                disabled={!apiHelper.isAdmin()}
                                required
                            />
                        </div>
                    </div>
                </div>
                {alert}
            </div>
        </div>
    );
};

export default RequestDetail;
