import React from 'react';
import moment from 'moment';

export default {
  formatFromTarget: target => {
    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = Number(target.value);
        break;
      case 'react-datetime':
        value = moment(target.value).toISOString();
        break;
      default:
        value = target.value;
    }
    return value;
  },

  formatDateForAPI: date => {
    return moment(date).toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  formatDate: date => {
    return moment(date).format('DD/MM/YYYY');
  },

  formatDateTime: date => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  },

  formatNumberDecimals: (number, decimals = 5) => {
    const decimalPlaces = 10 ** decimals;
    number = Math.round(number * decimalPlaces) / decimalPlaces;
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  },
};
