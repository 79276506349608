import React from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';

const SelectRequestState = props => {
    const [options, setOptions] = useState([
        { value: "PA", label: 'Para armar' },
        { value: "A", label: 'Armado' },
        { value: "NE", label: 'No entregado' },
        { value: "E", label: 'Entregado' },
    ]);

    const onChangeWrapper = value => {
        let event = { target: {} };
        event.target.name = props.name ? props.name : 'select-nameless';
        event.target.value = value.value;
        event.target.type = 'react-select';
        props.onChange(event);
    };

    let value = props.value;

    if (value !== undefined) {
        value = options.find(option => option.value === value);
    }

    return (
        <Select
            className={props.selectClassName}
            name={props.name}
            onChange={onChangeWrapper.bind(this)}
            value={value || ''}
            required={props.required}
            options={options}
            clearable={false}
            placeholder="Estado"
            menuContainerStyle={{ zIndex: 999 }}
            disabled={props.disabled}
            enuContainerStyle={{ zIndex: 999 }}
        />
    );
};

export default SelectRequestState;
